
export const getResponseTopic = (chargerId) => {
    if (chargerId == null)
        return;

    return "/cms/" + chargerId + "/response";
}

export const getRequestTopic = (chargerId) => {
    if (chargerId == null)
        return;

    return "/cms/" + chargerId;
}