export const TYPE_TECHNICIAN = "technician";


export const isTechnician = (user) => {
    if(!user)
        return false;

    if(!user.type)
        return false;

    return (user.type === TYPE_TECHNICIAN);
}


