import React, { Component } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import MiniWidgets from "../Dashboard/MiniWidgets";
import { Link } from "react-router-dom";
import LightData from "../Maps/LightData";

const mapStyles = {
  width: "65%",
  height: "81%",
};

class Locations extends Component {
  render() {
    const cardData = [
      {
        title: "Yashobhoomi Convention Centre",
        description: " Chargers: 3",
        info: " Sessions: 67",
      },
    ];

    return (
      <div style={styles.container}>
        <div style={styles.card}>
          {cardData.map((card, index) => (
            <Link to="cms-chargers" className="text-dark">
              <div key={index} style={styles.card}>
                <h2>{card.title}</h2>
                <i className="ri-charging-pile-2-fill"></i>
                <span> {card.description}</span>
                <p>
                  <i className="ri-stack-line"></i>
                  <strong>{card.info}</strong>
                </p>
              </div>
            </Link>
          ))}
        </div>
        <div style={styles.map}>
          <Map
            google={this.props.google}
            zoom={9}
            styles={LightData.Data}
            style={mapStyles}
            initialCenter={{
              lat: 28.5547565, // Latitude for India
              lng: 77.043062, // Longitude for India
            }}
          >
            {/* <Marker
              position={{
                lat: 12.9843873, // Latitude for Bangalore
                lng: 77.7420796, // Longitude for Bangalore
              }}
              title={"Bangalore"}
              name={"Bangalore"}
            /> */}
            <Marker
              position={{
                lat: 28.5547565, // Latitude for Bangalore
                lng: 77.043062, // Longitude for Bangalore
              }}
              title={"Yashobhhomi"}
              name={"Yashobhoomi"}
            />
          </Map>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    height: "100vh",
    marginTop: "100px",
  },
  cardContainer: {
    width: "25%",
    padding: "20px",
    boxSizing: "border-box",
    backgroundColor: "#f8f9fa",
    borderRight: "1px solid #dee2e6",
    overflowY: "auto",
  },
  card: {
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0 0 5px rgba(0,0,0,0.1)",
    borderRadius: "5px",
  },
  map: {
    width: "75%",
    height: "100%",
  },
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBcgqRhNZTzePlgvGoB3mgYqE_fEkL1yuA",
})(Locations);
