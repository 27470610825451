import * as actions from "./actionTypes";

export const chargerSelected = (chargerId) => {

    if (chargerId == null)
        return null;

    return {
        type: actions.CHARGER_SELECTED,
        payload: {
            chargerId
        }
    }
}