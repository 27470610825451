import React, { Component } from "react";
// import apexChart
import LineApexChart from "../AllCharts/apex/chartapex";
import DashedLine from "../AllCharts/apex/dashedLine";
import SplineArea from "../AllCharts/apex/SplineArea";
import Apaexlinecolumn from "../AllCharts/apex/apaexlinecolumn";
import ColumnWithDataLabels from "../AllCharts/apex/ColumnWithDataLabels";
import BarChart from "../AllCharts/apex/barchart";
import LineColumnArea from "../AllCharts/apex/LineColumnArea";
import RadialChart from "../AllCharts/apex/RadialChart";
import PieChart from "../AllCharts/apex/PieChart";
import DonutChart from "../AllCharts/apex/dountchart";
import Knob from "../AllCharts/knob/knob";

import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactApexChart from "react-apexcharts";
import { lkgreen, lkred } from "./styles";

class ActiveKnob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Charts", link: "#" },
        { title: "Apex charts", link: "#" },
      ],

      series: [66, 33],
      options: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                label: "Active-sessions",
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 2;
                },
              },
            },
          },
        },

        //labels: ['Active Session', 'Fault', 'Active Session', 'Fault'],
        // colors: ['#556ee6', '#34c38f', '#f46a6a', '#34c38f'],

        labels: ["Active-session", "Fault"],
        colors: [lkgreen, lkred],
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-right">
              {false && (
                <select className="custom-select custom-select-sm">
                  <option value="1">Jan</option>
                  <option defaultValue>Feb</option>
                  <option value="2">Mar</option>
                  <option value="3">Apr</option>
                  <option value="4">May</option>
                  <option value="5">Jun</option>
                  <option value="6">Jul</option>
                  <option value="7">Aug</option>
                  <option value="8">Sep</option>
                  <option value="9">Oct</option>
                  <option value="10">Nov</option>
                  <option value="11">Dec</option>
                </select>
              )}
            </div>
            <CardTitle className="mb-4">Active Sessions</CardTitle>

            <div className="text-center" dir="ltr">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="radialBar"
                height="380"
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default ActiveKnob;
