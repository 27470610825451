import React from "react";
import { Row, Col, Container } from "reactstrap";

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                           { false && <span style={{ "font-size": "15px" }}>{new Date().getFullYear()} © <b>iqlabs.io</b></span>}
                        </Col>
                        <Col sm={6}>
                            {/*<div className="text-sm-right d-none d-sm-block">
                                    Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign
                                </div>*/}

                            <div className="text-sm-right d-none d-sm-block">
                               { false && <p> <span style={{ "font-size": "18px" }}> powered by <b>iqlabs.io</b></span></p>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
