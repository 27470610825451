import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <span style={{ "font-size": "15px" }}>{new Date().getFullYear()} © <b>iqlabs.io</b></span>
                        </Col>
                        <Col sm={6}>

                            {/* <div className="text-sm-right d-none d-sm-block">
                                    Crafted with <i className="mdi mdi-heart text-danger"></i> by iqlabs.io
                                </div>*/}


                            <div className="text-sm-right d-none d-sm-block">
                                <p> <span style={{ "font-size": "20px" }}> powered by <b>iqlabs.io</b></span></p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
