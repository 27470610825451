import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { lkgreen, lkred, lkprimary } from "./styles";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

class FaultAnalytics extends Component {
  state = {
    series: [27, 25, 10, 30, 8],
    options: {
      labels: [
        "Power-failure",
        "ESB-pressed",
        "Meter-failure",
        "Earth-leakage",
        "Vendor-specific",
      ],
      plotOptions: {
        pie: {
          pie: {
            size: "150%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      // colors: ['#f46a6a', '#1cbb8c', '#eeb902'],
      colors: ["#f46a6a", "#ff5b00", "#ff8f00", "#ffc302", "#fff600"],
    },
  };
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-right">
              <select className="custom-select custom-select-sm">
                <option value="1">Jan</option>
                <option defaultValue>Feb</option>
                <option value="2">Mar</option>
                <option value="3">Apr</option>
                <option value="4">May</option>
                <option value="5">Jun</option>
                <option value="6">Jul</option>
                <option value="7">Aug</option>
                <option value="8">Sep</option>
                <option value="9">Oct</option>
                <option value="10">Nov</option>
                <option value="11">Dec</option>
              </select>
            </div>
            <h4 className="card-title mb-4">Fault Analytics</h4>

            <div id="pie-chart" className="apex-charts">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="pie"
                height="200"
              />
            </div>

            <Row>
              <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i
                      className="mdi mdi-circle font-size-10 mr-1"
                      style={{ color: "#f46a6a" }}
                    ></i>{" "}
                    Power-failure
                  </p>
                  <h5>27 %</h5>
                </div>
              </Col>
              <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i
                      className="mdi mdi-circle font-size-10 mr-1"
                      style={{ color: "#ff5b00" }}
                    ></i>
                    <Link
                      to={{
                        pathname:
                          "http://localhost:8800/doku.php?id=esbpressed",
                      }}
                      target="_blank"
                      className="text-primary font-weight-bold"
                      style={{ color: "#0000FF" }}
                    >
                      ESBPressed
                    </Link>
                  </p>
                  <h5>25 %</h5>
                </div>
              </Col>

              <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i
                      className="mdi mdi-circle font-size-10 mr-1"
                      style={{ color: "#ff8f00" }}
                    ></i>{" "}
                    Meter-failure
                  </p>
                  <h5>10 %</h5>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i
                      className="mdi mdi-circle font-size-10 mr-1"
                      style={{ color: "#ffc302" }}
                    ></i>{" "}
                    Earth-leakage
                  </p>
                  <h5>30 %</h5>
                </div>
              </Col>

              <Col xs={4}>
                <div className="text-center mt-4">
                  <p className="mb-2 text-truncate">
                    <i
                      className="mdi mdi-circle font-size-10 mr-1"
                      style={{ color: "#fff600" }}
                    ></i>{" "}
                    Vendor-specific
                  </p>
                  <h5>8 %</h5>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default FaultAnalytics;
