export const renderStatus = (chargerStatus) => {
    if (chargerStatus === "Charging") {
        //return "mr-2 text-success";


        return "p-2 mr-2 text-white bg-success rounded";
    }
    if (chargerStatus === "Available")
        return "mr-2 text-success";
    //return "p-1 mr-2 text-white bg-primary rounded";

    if (chargerStatus === "Preparing")
        return "mr-2 text-warning";
    //return "p-1 mr-2 text-white bg-warning rounded";
    if (chargerStatus === "Faulted")
        // return "mr-2 text-danger";
        return "p-2 mr-2 text-white bg-danger rounded";
    if (chargerStatus === "Finishing")
        return "mr-2 text-warning";

    if (chargerStatus === "Offline")
        return "mr-2 text-danger";
    //return "p-1 mr-2 text-white bg-warning rounded";

    //return "mr-2 text-info";
    return "p-1 mr-2 text-white bg-info rounded";
}