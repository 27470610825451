import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Media,
  Modal,
  Alert,
  Label,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Images
import img1 from "../../assets/images/companies/iicc-logo-new.png";
import img2 from "../../assets/images/companies/sobha.png";
import img3 from "../../assets/images/companies/7_11.png";
import img4 from "../../assets/images/companies/hilton_1.png";
import img5 from "../../assets/images/companies/pappa_roti_2.png";
import img6 from "../../assets/images/companies/burger_meester_2.png";
import img7 from "../../assets/images/companies/wallmart.jpg";
import img8 from "../../assets/images/companies/marriot.png";

let location = {
  name: "",
  lat: "12.9843873",
  long: "77.7420796",
  address: "",
};

class Shops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Energy Dash", link: "#" },
        { title: "Locations", link: "#" },
      ],
      shops: [
        {
          img: img1,
          name: "Block 1",
          owner: "Raju",
          products: "3",
          balance: "NA",
          isLinked: true,
        },
        {
          img: img1,
          name: "Block 2",
          owner: "Raju",
          products: "0",
          balance: "NA",
          isLinked: false,
        },

        /*{ img: img3, name: "Seven Eleven", owner: "Joseph DePinto", products: "5", balance: "3,73,613" },
                { img: img4, name: "Conrad", owner: "Nicky Hilton Rothschild", products: "6", balance: "5,46,300" },
                { img: img5, name: "Pappa Roti", owner: "Rasha Al Danhani", products: "2", balance: "66,688" },
                { img: img6, name: "Burger Meester", owner: "Justus de Nijs", products: "7", balance: "4,32,113" },
                { img: img7, name: "Wallmart", owner: "Doug McMillon", products: "6", balance: "9,90,850" },
                { img: img8, name: "Marriott", owner: "Anthony Capuano", products: "3", balance: "9,99,050" }, */
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Locations"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <div>
              <Link
                to="#"
                onClick={() =>
                  this.setState({ modal_static: true, isAlertOpen: false })
                }
                className="btn btn-success mb-2"
              >
                <i className="mdi mdi-plus mr-2"></i> Add Location
              </Link>
            </div>
            <br></br>
            <Row>
              {this.state.shops.map((shop, key) => (
                <Col xl={3} sm={6} key={key}>
                  <Card>
                    <CardBody>
                      <Link
                        to={shop.isLinked ? "/cms-chargers" : "#"}
                        className="text-dark"
                      >
                        <div className="text-center">
                          <img
                            src={shop.img}
                            alt=""
                            className="avatar-md mt-3 mb-2"
                          />
                          <Media body>
                            <h5 className="text-truncate">{shop.name}</h5>
                            <p className="text-muted">
                              <i className="mdi mdi-account mr-1"></i>{" "}
                              {shop.owner}
                            </p>
                          </Media>
                        </div>
                      </Link>

                      <hr className="my-4" />

                      <Row className="text-center">
                        <Col xs={6}>
                          <p className="text-muted mb-2">Chargers</p>
                          <h5>{shop.products}</h5>
                        </Col>
                        <Col xs={6}>
                          <p className="text-muted mb-2">Sessions</p>
                          <h5>{shop.balance}</h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>

            {/* <Row>
              <div
                id="gmaps-markers"
                className="gmaps"
                style={{ position: "relative" }}
              >
                <Map
                  google={this.props.google}
                  style={{ width: "100%", height: "100%" }}
                  zoom={14}
                >
                  <Marker
                    title={"The marker`s title will appear as a tooltip."}
                    name={"SOMA"}
                    position={{ lat: 12.9969321, lng: 77.6041706 }}
                  />
                  <Marker name={"Dolores park"} />
                  <InfoWindow>
                    <div>
                      <h1>{this.state.selectedPlace.name}</h1>
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </Row> */}

            <Row>
              <Col xl={12}>
                <div className="text-center my-3">
                  <Link to="#" className="text-primary">
                    <i className="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2"></i>{" "}
                    Load more{" "}
                  </Link>
                </div>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modal_static}
              toggle={this.tog_static}
              backdrop="static"
              centered
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
              >
                Add Location Details
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.addCustomer} model={location}>
                  <Row>
                    <Col lg={12}>
                      <Alert
                        color="success"
                        isOpen={this.state.isAlertOpen}
                        toggle={() => this.setState({ isAlertOpen: false })}
                      >
                        Data Added Successfully...!
                      </Alert>
                      <FormGroup>
                        <Label htmlFor="name">Location Name*</Label>
                        <AvField
                          name="custname"
                          type="text"
                          className="form-control"
                          id="locname"
                          placeholder="Enter Location Name"
                          value={location.name}
                          required
                        ></AvField>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="number">Latitude*</Label>
                        <AvField
                          name="lat"
                          type="float"
                          className="form-control"
                          id="loclat"
                          placeholder="Enter Latitude"
                          value={location.lat}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="email">Longitude*</Label>
                        <AvField
                          name="long"
                          type="float"
                          className="form-control"
                          id="loclong"
                          placeholder="Enter Longitude"
                          value={location.long}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="number">Contact Name*</Label>
                        <AvField
                          name="cName"
                          type="text"
                          className="form-control"
                          id="locContactName"
                          placeholder="Enter Contact Name"
                          value=""
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor="email">Phone Number*</Label>
                        <AvField
                          name="cNumb"
                          type="text"
                          className="form-control"
                          id="locContactNumber"
                          placeholder="Enter Contact Number"
                          value=""
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <Label htmlFor="address">Address</Label>
                        <AvField
                          name="addr"
                          type="text"
                          className="form-control"
                          id="locaddr"
                          placeholder="Enter Address"
                          value={location.address}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <ModalFooter>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modal_static: false })}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary">
                      {location.name ? "Update" : "Add"}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Shops;
