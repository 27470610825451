import React, { Component } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
//import Axios from "axios";




/* Axios.get("https://backend.iqlabs.io/api/evChargers/transactions", {
  params: {
    token: "8lQzN922rpRDaMbh3m5J",
    id: "#"
  }
})
  .then((res) => {


    let i = 0;
    res.data.data.reverse().forEach((item, index) => {
      trans_data.push({
        id: ++i,
        conn: item.connectorId,
        orderId: <Link to="#" className="text-dark font-weight-bold">{item.chargerId}</Link>,
        date: item.startTime,
        billingName: item.userId,
        total: "\u20B9" + ((parseInt(item.meterStop, 10) - parseInt(item.meterStart, 10)) / 1000 * 10).toString(),
        consumption: ((parseInt(item.meterStop, 10) - parseInt(item.meterStart, 10)) / 1000).toString(),
        status: <div className="badge badge-soft-success font-size-12">Paid</div>,

      })
    });

    //console.log("Transactions", trans_data);



  }); */



const expandRow = {
  renderer: row => (
    <>
      Action :
      <Link to="#" className="mr-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
      <Link to="#" className="text-danger" ><i className="mdi mdi-trash-can font-size-18"></i></Link>
    </>
  ),

  showExpandColumn: true,
  expandByColumnOnly: true
};

class LatestTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      transData: this.props.transaction_data
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {

    //console.log("Latest Transactions Next Props", nextProps.transaction_data);
    if (nextProps.transaction_data !== this.state.transData)
      this.setState({ transData: nextProps.transaction_data });
  }

  render() {



    let data = {
      columns: [
        {
          dataField: 'id',
          text: 'No.'
        },
        {
          dataField: "transactionId",
          text: "Transaction ID"
        },
        {
          dataField: "orderId",
          text: "Charger ID"
        },
        {
          dataField: 'conn',
          text: 'Conn'
        },
        {
          dataField: "date",
          text: "Date & Time"
        },
        {
          dataField: "billingName",
          text: "UserId (RFID)"
        },
        {
          dataField: "total",
          text: "Total"
        },
        {
          dataField: "consumption",
          text: "kWh"
        },
        {
          dataField: "status",
          text: "Payment Status"
        },
      ],

      rows: this.state.transData,
      /*rows: [
        {
          id: 1,
          conn: 1,
          orderId: <Link to="#" className="text-dark font-weight-bold">#CF1572</Link>,
          date: "04 Nov, 2022",
          billingName: "Anakshu5079",
          total: "₹ 50",
          consumption: "5",
          status: <div className="badge badge-soft-success font-size-12">Paid</div>,
        },
        {
          id: 2,
          conn: 2,
          orderId: <Link to="#" className="text-dark font-weight-bold">#CF1571</Link>,
          date: "02 Nov, 2022",
          billingName: "JohnDoe2",
          total: "₹ 125",
          consumption: "12.5",
          status: <div className="badge badge-soft-warning font-size-12">unpaid</div>,
        },
        {
          id: 3,
          conn: 1,
          orderId: <Link to="#" className="text-dark font-weight-bold">#CF1570</Link>,
          date: "01 Nov, 2022",
          billingName: "Ashok.Kumar",
          total: "₹ 146",
          consumption: "14.6",
          status: <div className="badge badge-soft-success font-size-12">Paid</div>,
        },
        {
          id: 4,
          conn: 1,
          orderId: <Link to="#" className="text-dark font-weight-bold">#CF1569</Link>,
          date: "01 Nov, 2022",
          billingName: "Phoenix",
          total: "₹ 90.7",
          consumption: "9.07",
          status: <div className="badge badge-soft-success font-size-12">Paid</div>,
        },
        {
          id: 5,
          conn: 1,
          orderId: <Link to="#" className="text-dark font-weight-bold">#CF1568</Link>,
          date: "01 Nov, 2022",
          billingName: "LalitG",
          total: "₹ 81",
          consumption: "8.1",
          status: <div className="badge badge-soft-danger font-size-12">Chargeback</div>,
        },
        {
          id: 6,
          conn: 2,
          orderId: <Link to="#" className="text-dark font-weight-bold">#CF1567</Link>,
          date: "03 Nov, 2021",
          billingName: "Anirudh_Harish",
          total: "₹ 54",
          consumption: "5.4",
          status: <div className="badge badge-soft-warning font-size-12">unpaid</div>,
        },
        {
          id: 7,
          conn: 1,
          orderId: <Link to="#" className="text-dark font-weight-bold">#CF1566</Link>,
          date: "03 Nov, 2021",
          billingName: "Walter.Golmes",
          total: "₹ 252",
          consumption: "20",
          status: <div className="badge badge-soft-success font-size-12">Paid</div>,
        },
        {
          id: 8,
          conn: 2,
          orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1565</Link>,
          date: "03 Nov, 2021",
          billingName: "Abdul_Aleim",
          total: "₹ 306",
          consumption: "15.3",
          status: <div className="badge badge-soft-success font-size-12">Paid</div>,
        },
        {
          id: 9,
          conn: 2,
          orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1564</Link>,
          date: "03 Nov, 2021",
          billingName: "Abinav_Sankar",
          total: "₹ 117",
          consumption: "11.7",
          status: <div className="badge badge-soft-success font-size-12">Paid</div>,
        },
        {
          id: 10,
          conn: 2,
          orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1563</Link>,
          date: "03 Nov, 2021",
          billingName: "Aditya.T",
          total: "₹ 27",
          consumption: "2.7",
          status: <div className="badge badge-soft-warning font-size-12">unpaid</div>,
        },
        {
          id: 11,
          conn: 1,
          orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1562</Link>,
          date: "03 Nov, 2021",
          billingName: "Sachin09H",
          total: "₹ 90",
          consumption: "9",
          status: <div className="badge badge-soft-success font-size-12">Paid</div>,
        },
      ]*/
    };

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList:
        [{
          text: '5th', value: 5
        }, {
          text: '10th', value: 10
        }, {
          text: 'All', value: this.state.transData.length
        }]

    };

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true
    };

    return (
      <React.Fragment>
        <Col >
          <Card>
            <CardBody>
              <Dropdown isOpen={this.state.menu} toggle={() => this.setState({ menu: !this.state.menu })} className="float-right">
                <DropdownToggle tag="i" className="arrow-none card-drop">
                  <i className="mdi mdi-dots-vertical"></i>
                </DropdownToggle>
                <DropdownMenu right>

                  <DropdownItem>Sales Report</DropdownItem>

                  <DropdownItem>Export Report</DropdownItem>

                  <DropdownItem>Profit</DropdownItem>

                  <DropdownItem>Action</DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <h4 className="card-title mb-4">Latest Transactions</h4>

              {/*<BootstrapTable
                keyField='id'
                data={data.rows}
                columns={data.columns}
                expandRow={expandRow}
                pagination={paginationFactory(options)}
                selectRow={selectRow}
              />*/}

              <BootstrapTable
                keyField='id'
                data={this.state.transData}
                columns={data.columns}
                pagination={paginationFactory(options)}
              />
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default LatestTransactions;