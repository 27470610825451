import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Alert,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Charger Management System", link: "#" },
        { title: "Users", link: "#" },
      ],
      isAlertOpen: false,
      modal_static: false,
      data: [
        {
          user: (
            <Link
              to={{
                pathname: "cms-user-details",
                user: "Raju Pillai",
                email: "raju.pillai@lntebg.com",
                phone: "9847040400",
                rfid: "NA",
                date: "28 April, 2024",
              }}
            >
              Raju Pillai
            </Link>
          ),
          email: "raju.pillai@lntebg.com",
          phone: "9847040400",
          rfid: "NA",
          date: "28 April, 2024",
        },
        {
          user: (
            <Link
              to={{
                pathname: "cms-user-details",
                user: "Sagarika Agarwal",
                email: "sagarika.agarwal@lntebg.com",
                phone: "9939004141",
                rfid: "NA",
                date: "18 May, 2024",
              }}
            >
              Sagarika Agarwal
            </Link>
          ),
          email: "sagarika.agarwal@lntebg.com",
          phone: "9939004141",
          rfid: "NA",
          date: "18 May, 2024",
        },
        {
          user: (
            <Link
              to={{
                pathname: "cms-user-details",
                user: "Chethan Kumar",
                email: "chethan.kumar@i-pec.in",
                phone: "9902062209",
                rfid: "NA",
                date: "28 Feb, 2024",
              }}
            >
              Chethan Kumar
            </Link>
          ),
          email: "chethan.kumar@i-pec.in",
          phone: "9902062209",
          rfid: "NA",
          date: "28 Feb, 2024",
        },
        {
          user: (
            <Link
              to={{
                pathname: "cms-user-details",
                user: "Shubham Shintre",
                email: "shubham.r@i-pec.in",
                phone: "8788097653",
                rfid: "NA",
                date: "28 Feb, 2024",
              }}
            >
              Shubham Shintre
            </Link>
          ),
          email: "shubham.r@i-pec.in",
          phone: "8788097653",
          rfid: "NA",
          date: "28 Feb, 2024",
        },
      ],
    };
    this.addCustomer.bind(this);
  }

  addCustomer = (event, values) => {
    //Assign values to the variables
    var name = values.custname;
    var cEmail = values.custemail;
    var phonenumber = values.phonenumber;
    var newRfid = "$ " + values.rfid;
    var d = new Date();
    var day = d.getDate();
    var mon = d.getMonth();
    var y = d.getFullYear();
    var date = day + "/" + mon + "/" + y;
    let demoData = this.state.data;

    //push data to the varible
    demoData.push({
      customer: name,
      email: cEmail,
      phone: phonenumber,
      rfid: newRfid,
      date: date,
    });

    //update data state
    this.setState({ data: demoData });

    //show alert for success message
    this.setState({ isAlertOpen: true });

    //update state for closing
    setTimeout(() => {
      this.setState({ modal_static: false });
    }, 2000);
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Customers"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div>
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            modal_static: true,
                            isAlertOpen: false,
                          })
                        }
                        className="btn btn-success mb-2"
                      >
                        <i className="mdi mdi-plus mr-2"></i> Add Customer
                      </Link>
                    </div>
                    <div className="table-responsive mt-3">
                      <Table
                        className="table-centered datatable dt-responsive nowrap "
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th style={{ width: "20px" }}>
                              <div className="custom-control custom-checkbox">
                                <Input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customercheck"
                                />
                                <Label
                                  className="custom-control-label"
                                  htmlFor="customercheck"
                                >
                                  &nbsp;
                                </Label>
                              </div>
                            </th>
                            <th>User</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Rfid</th>
                            <th>Joining Date</th>
                            <th style={{ width: "120px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((customerData, key) => (
                            <tr key={key}>
                              <td>
                                <div className="custom-control custom-checkbox">
                                  <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={"customercheck" + key}
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor={"customercheck" + key}
                                  >
                                    &nbsp;
                                  </Label>
                                </div>
                              </td>

                              <td>{customerData.user}</td>
                              <td>{customerData.email}</td>
                              <td>{customerData.phone}</td>

                              <td>{customerData.rfid}</td>
                              <td>{customerData.date}</td>
                              <td>
                                <Link
                                  to="#"
                                  className="mr-3 text-primary"
                                  id={"edit" + key}
                                >
                                  <i className="mdi mdi-pencil font-size-18"></i>
                                </Link>
                                <UncontrolledTooltip
                                  target={"edit" + key}
                                  placement="top"
                                >
                                  Edit
                                </UncontrolledTooltip>
                                <Link
                                  to="#"
                                  className="text-danger"
                                  id={"delete" + key}
                                >
                                  <i className="mdi mdi-trash-can font-size-18"></i>
                                </Link>
                                <UncontrolledTooltip
                                  target={"delete" + key}
                                  placement="top"
                                >
                                  Delete
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modal_static}
              toggle={this.tog_static}
              backdrop="static"
              centered
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
              >
                Add Customer Details
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={this.addCustomer}>
                  <Row>
                    <Col lg={12}>
                      <Alert
                        color="success"
                        isOpen={this.state.isAlertOpen}
                        toggle={() => this.setState({ isAlertOpen: false })}
                      >
                        Data Added Successfully...!
                      </Alert>
                      <FormGroup>
                        <Label htmlFor="name">Customer Name</Label>
                        <AvField
                          name="custname"
                          type="text"
                          className="form-control"
                          id="custname"
                          placeholder="Enter Customer Name"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={4}>
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <AvField
                          name="custemail"
                          type="email"
                          className="form-control"
                          id="custemail"
                          placeholder="Enter Email"
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4}>
                      <FormGroup>
                        <Label htmlFor="email">Phone Number</Label>
                        <AvField
                          name="phonenumber"
                          type="number"
                          className="form-control"
                          id="phonenumber"
                          placeholder="Enter Phone Number"
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4}>
                      <FormGroup>
                        <Label htmlFor="email">Rfid</Label>
                        <AvField
                          name="wBalance"
                          type="number"
                          className="form-control"
                          id="rfid"
                          placeholder="Rfid"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modal_static: false })}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary">
                      Add
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Customers;
