
import * as actions from "./actionTypes";

const INIT_STATE = {
    selectedChargerId: ""
}

const ChargerManagement = (state = INIT_STATE, action) => {


    if (action.type === actions.CHARGER_SELECTED) {

        if (action.payload.chargerId === null)
            return state;

        return {
            ...state,
            selectedChargerId: action.payload.chargerId
        }
    }

    return state;

}

export default ChargerManagement;