import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { lkred } from "../../Dashboard/styles";

class barchart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      faults: [
        {
          name: "Count",
          //categories: ['Power-failure', 'ESB-pressed', 'Meter-failure', 'PowerBoard-failure', 'Earth-leakage', 'PowerBoard-failure', 'Over-voltage', 'Under-voltage', 'No-load', 'Over-current'],
          data: [30, 43, 20, 5, 5, 3, 10, 2, 18, 0],
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },

        colors: [lkred],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: [
            "Power-failure",
            "ESB-pressed",
            "Meter-failure",
            "PowerBoard-failure",
            "Earth-leakage",
            "Over-temperature",
            "Over-voltage",
            "Under-voltage",
            "No-load",
            "Over-current",
          ],
        },
      },
    };
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.faults}
          type="bar"
          height="351"
        />
      </React.Fragment>
    );
  }
}

export default barchart;
