import React, { Component, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Media,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  UncontrolledTooltip,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "../Dashboard/MiniWidgets";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
//Import Product Images
import img1 from "../../assets/images/product/img-1.png";
import img5 from "../../assets/images/product/img-5.png";
import img3 from "../../assets/images/product/img-3.png";
import img4 from "../../assets/images/product/img-4.png";
import lev from "../../assets/images/product/lev_charger.jpg";
import ac06 from "../../assets/images/product/cfac06.jpg";
import ac06m from "../../assets/images/product/cfac06m.png";

import user1 from "../../assets/images/users/avatar-2.jpg";

import io from "socket.io-client";
import { connect } from "react-redux";
import { chargerSelected } from "../../store/chargers/actions";
import { MDBDataTable } from "mdbreact";

let socket;
let userId_1 = "6076A1ACCD98";
let userId_2 = "F8474CEF49C0";
let userId_3 = "20898795AB34";

//const rating = "7.2";
let pollInterval;
const expandRow = {
  renderer: (row) => (
    <>
      Action :
      <Link to="#" className="mr-3 text-primary">
        <i className="mdi mdi-pencil font-size-18"></i>
      </Link>
      <Link to="#" className="text-danger">
        <i className="mdi mdi-trash-can font-size-18"></i>
      </Link>
    </>
  ),

  showExpandColumn: true,
  expandByColumnOnly: true,
};

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Charger Management System", link: "#" },
        { title: "User Details", link: "#" },
      ],
      activeTab: "1",
      activeTab2: "1",
      chargerStatus: "Offline",
      start_btn_clr: "#5664D2",
      stop_btn_clr: "#EFF2F7",
      start_btn_txt_clr: "#ffffff",
      stop_btn_txt_clr: "#000000",
      chargerId: props.selectedChargerId,
      //userId: props.selectedUserId,
      userId: props.location.phone,
      transLog: [],
      faultLog: [],
      consumption: "Syncing...",
      ActiveTransactionId: 0,
      ocppId: "",
      syncIntervalId: "",
      imgSrc: user1,
      rating: "7.2",
      lasthb: "",
      perUnitRate: 0,
      currency: "₹",
      protocol: "Ocpp1.6",
      connectors: "",
      firmwareVersion: "",
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleTab2 = this.toggleTab2.bind(this);
    this.imageShow = this.imageShow.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleTab2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      });
    }
  }

  toggleChargeButtons = (chargerStatus) => {
    if (chargerStatus == null) return;

    if (chargerStatus === "Charging") {
      this.setState({
        start_btn_clr: "#EFF2F7",
        stop_btn_clr: "#FF3D60",
        start_btn_txt_clr: "#000000",
        stop_btn_txt_clr: "#ffffff",
      });
    } else {
      this.setState({
        start_btn_clr: "#5664D2",
        stop_btn_clr: "#EFF2F7",
        start_btn_txt_clr: "#ffffff",
        stop_btn_txt_clr: "#000000",
      });
    }
  };

  imageShow(img, id) {
    var expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = ac06;
  }

  updateChargerImage = () => {
    /*   let src = ac06;
  
          if (this.state.chargerId === null)
              src = ac06;
  
          if (this.state.chargerId === chargerId_1)
              src = ac06m;
  
          if (this.state.chargerId === chargerId_2)
              src = lev;
  
          if (this.state.chargerId === chargerId_3)
              src = ac06;
  
          this.setState({ imgSrc: src }); */
  };

  renderStatus = () => {
    if (this.state.chargerStatus === "Charging") {
      //return "mr-2 text-success";

      return "p-2 mr-2 text-white bg-success rounded";
    }
    if (this.state.chargerStatus === "Available") return "mr-2 text-success";
    //return "p-1 mr-2 text-white bg-primary rounded";

    if (this.state.chargerStatus === "Preparing") return "mr-2 text-warning";
    //return "p-1 mr-2 text-white bg-warning rounded";
    if (this.state.chargerStatus === "Faulted")
      // return "mr-2 text-danger";
      return "p-2 mr-2 text-white bg-danger rounded";
    if (this.state.chargerStatus === "Finishing") return "mr-2 text-warning";

    if (this.state.chargerStatus === "Offline") return "mr-2 text-danger";
    //return "p-1 mr-2 text-white bg-warning rounded";

    //return "mr-2 text-info";
    return "p-1 mr-2 text-white bg-info rounded";
  };

  /*  socketSend = (topic, payload) => {
         if (topic == null)
             return;
 
         if (payload == null)
             return;
 
         if ((topic.toString().length <= 0) || (payload.toString().length <= 0))
             return;
 
         if (socket == null)
             return;
 
         if (!socket.active)
             return;
 
         socket.emit("publish", JSON.stringify({ topic, payload }));
     } */

  /*  msgSubscribe = (topic) => {
         if (socket == null)
             return;
 
         if (!socket.active)
             return;
 
         socket.emit("subscribe", { topic: this.getResponseTopic(this.state.chargerId) });
     }
 
     getResponseTopic = (chargerId) => {
         if (chargerId == null)
             return;
 
         return "/cms/" + chargerId + "/response";
     }
 
     getRequestTopic = (chargerId) => {
         if (chargerId == null)
             return;
 
         return "/cms/" + chargerId;
     } */

  async componentDidMount() {
    /* if (this.props.selectedChargerId != null) {
            if (this.state.chargerId !== this.props.selectedChargerId) {
                this.setState({ chargerId: this.props.selectedChargerId, transLog: [] });


                console.log("Update ChargerId", this.state.chargerId);
            }
        } */

    try {
      //await this.updateUserDetails(this.state.userId);

      console.log("UserDetail Props", this.props);

      const charger_status = await this.updateUserTransactions(
        this.state.userId
      );
    } catch (e) {
      console.log("Charger Details Update Error", e.toString());
    }

    const intervalId = setInterval(async () => {
      try {
        //console.log("Charger Update Routine");
        await this.updateUserDetails(this.state.userId);
        await this.updateUserTransactions(this.state.userId);
      } catch (e) {
        console.log("Charger Details Update Error", e.toString());
      }
    }, 5000);

    this.setState({ syncIntervalId: intervalId });

    /* socket = io("https://backend.iqlabs.io:3000");


        socket.on("connect", () => {
            console.log("Socket Connection Successful");

            console.log("Response Topic", this.getResponseTopic(this.state.chargerId))

            socket.emit("subscribe", { topic: this.getResponseTopic(this.state.chargerId) });

            socket.on("mqtt", msg => {

                if (msg == null)
                    return;

                //console.log("Rx Msg", msg);
                const msgJson = JSON.parse(msg);
                const topic = msgJson.topic;
                const payload = JSON.parse(msgJson.payload);

                this.processMsg(payload);


            });

        });

        this.updateChargerImage(); */
  }

  async componentWillUnmount() {
    /*  console.log("Charger Page Exited");
 
         clearInterval(this.state.syncIntervalId);
 
         socket.emit("unsubscribe", { topic: this.getResponseTopic(this.state.chargerId) });
 
         socket.disconnect(); */

    this.setState({ transLog: [], consumption: "Syncing..." });
    clearInterval(this.state.syncIntervalId);
  }

  computeTime = (isodate) => {
    if (isodate == null) return "";

    const date = new Date(isodate);

    let hour = date.getHours().toString();
    let mins = date.getMinutes().toString();
    let secs = date.getSeconds().toString();

    if (hour.length < 2) hour = "0" + hour;

    if (mins.length < 2) mins = "0" + mins;

    if (secs.length < 2) secs = "0" + secs;

    return hour + ":" + mins + ":" + secs;
  };

  computeDate = (isodate) => {
    if (isodate == null) return "";

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(isodate);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear().toString();

    if (day < 10) day = "0" + day.toString();

    if (month < 10) month = "0" + month.toString();

    return day + " " + months.at(month) + ", " + year;
  };

  computeConsumption = (starMeterVal, stopMeterVal) => {
    if (isNaN(starMeterVal)) return "";

    if (isNaN(stopMeterVal))
      return (
        <div className="badge badge-soft-warning font-size-12">On-going</div>
      );

    return (
      (parseInt(stopMeterVal, 10) - parseInt(starMeterVal, 10)) /
      1000
    ).toString();
  };

  computeTotal = (starMeterVal, stopMeterVal) => {
    if (isNaN(stopMeterVal))
      return (
        <div className="badge badge-soft-warning font-size-12">On-going</div>
      );

    return (
      "\u20B9" +
      (
        ((parseInt(stopMeterVal, 10) - parseInt(starMeterVal, 10)) * 10) /
        1000
      ).toString()
    );
  };

  computePaymentStatus = (starMeterVal, stopMeterVal) => {
    if (isNaN(stopMeterVal))
      return <div className="badge badge-soft-warning font-size-12">Paid</div>;

    return <div className="badge badge-soft-success font-size-12">Paid</div>;
  };

  updateUserDetails = async (userId) => {
    if (userId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/users?token=8lQzN922rpRDaMbh3m5J&id=" +
        userId,
      requestOptions
    );
    const responseJson = await response.json();
    //console.log("Fetch data Reponse", responseJson);
    const data = responseJson.data;
    if (data.length <= 0) return;

    //console.log("Fetch data Reponse", data.at(0));
    // return data.at(0).status;
  };

  updateUserTransactions = async (userId) => {
    if (userId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/transactions/byUserId?token=8lQzN922rpRDaMbh3m5J&id=" +
        userId,
      requestOptions
    );
    const responseJson = await response.json();

    //console.log("Fetch Charger Details", responseJson.data[0]);
    if (responseJson.data == null) return;

    const data = responseJson.data;

    console.log("Fetch User Details", data);

    let trans_data = [];
    data.forEach((item) => {
      trans_data.push({
        checkbox: (
          <div className="custom-control custom-checkbox">
            <Input
              type="checkbox"
              className="custom-control-input"
              id="ordercheck1"
            />
            <Label className="custom-control-label" htmlFor="ordercheck1">
              &nbsp;
            </Label>
          </div>
        ),
        id: (
          <Link to="#" className="text-dark font-weight-bold">
            {item.chargerId}
          </Link>
        ),
        date:
          this.computeDate(item.startTime) +
          ", " +
          this.computeTime(item.startTime),
        userid: item.userId,
        conn: item.connectorId,
        kWh: this.computeConsumption(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        total: this.computeTotal(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        type: item.type,
        status: this.computePaymentStatus(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        invoice: (
          <Button className="btn-rounded" color="light">
            Invoice <i className="mdi mdi-download ml-2"></i>
          </Button>
        ),
        action: (
          <>
            <Link to="#" className="mr-3 text-primary" id="edit1">
              <i className="mdi mdi-pencil font-size-18"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="edit1">
              Edit
            </UncontrolledTooltip>
            <Link to="#" className="text-danger" id="delete1">
              <i className="mdi mdi-trash-can font-size-18"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="delete1">
              Delete
            </UncontrolledTooltip>
          </>
        ),
      });
    });

    if (JSON.stringify(trans_data) === JSON.stringify(this.state.transLog))
      return;

    this.setState({ transLog: trans_data });
  };

  getConnectorDetails = (connectors) => {
    if (connectors == null) return "";

    if (connectors.length <= 0) return "";

    let connDetails = "";

    if (connectors.length == 1) connDetails = "Single-gun ";
    else if (connectors.length == 2) connDetails = "Dual-gun ";
    else connDetails = "Muli-gun ";

    connectors.forEach((conn) => {
      connDetails += conn.connectorType + ";";
    });

    return connDetails;
  };

  getCurrencySymbol = (currency) => {
    if (currency == null) return "";

    switch (currency.toLowerCase()) {
      case "rupee":
        return "₹";

      case "usd":
        return "$";

      case "pound":
        return "£";

      default:
        return currency;
    }
  };

  updateChargerTransLog = async (chargerId) => {
    //console.log("Update Charger Id", chargerId);

    if (chargerId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/transactions?token=8lQzN922rpRDaMbh3m5J&id=" +
        chargerId,
      requestOptions
    );
    const responseJson = await response.json();
    //console.log("Fetch data Reponse", responseJson);
    const data = responseJson.data;

    //console.log("Data Fetched");
    let i = 0,
      trans_data = [];
    data.forEach((item) => {
      trans_data.push({
        connId: item.connectorId,
        chargerId: (
          <Link to="cms-charger-detail" className="text-dark font-weight-bold">
            {item.chargerId}
          </Link>
        ),
        date:
          this.computeDate(item.startTime) +
          ", " +
          this.computeTime(item.startTime),
        user: item.userId,
        transactionId: item.transactionId,
        total: this.computeTotal(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        units: this.computeConsumption(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
        status: this.computePaymentStatus(
          parseInt(item.meterStart, 10),
          parseInt(item.meterStop, 10)
        ),
      });
    });

    if (JSON.stringify(trans_data) === JSON.stringify(this.state.transLog))
      return;

    this.setState({ transLog: trans_data });
  };

  updateChargerFaultLog = async (chargerId) => {
    //console.log("Update Charger Id", chargerId);

    if (chargerId == null) return;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/statusLogs?token=8lQzN922rpRDaMbh3m5J&type=Faulted&id=" +
        chargerId,
      requestOptions
    );
    const responseJson = await response.json();
    //console.log("Fetch data Reponse", responseJson);
    const data = responseJson.data;

    data.sort((a, b) => -a.timeStamp.localeCompare(b.timeStamp));

    let fault_data = [];
    data.forEach((item) => {
      fault_data.push({
        connId: item.connectorId,
        chargerId: item.chargerId,
        timeStamp:
          this.computeDate(item.timeStamp) +
          " " +
          this.computeTime(item.timeStamp),
        details: item.details,
      });
    });

    if (JSON.stringify(fault_data) === JSON.stringify(this.state.faultLog))
      return;

    //console.log("Fault Data Log", data);

    this.setState({ faultLog: fault_data });
  };

  render() {
    /*   const data = {
              columns: [
                  {
                      dataField: 'transactionId',
                      text: 'Transaction Id'
                  },
  
                  {
                      dataField: "chargerId",
                      text: "Charger ID"
                  },
                  {
                      dataField: 'connId',
                      text: 'Conn'
                  },
                  {
                      dataField: "date",
                      text: "Date"
                  },
                  {
                      dataField: "user",
                      text: "User(RFID)"
                  },
                  {
                      dataField: "units",
                      text: "Units"
                  },
                  {
                      dataField: "total",
                      text: "Total"
                  },
  
                  {
                      dataField: "status",
                      text: "Payment Status"
                  },
              ],
              rows: [
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#CF1572</Link>,
                      date: "04 Nov, 2022",
                      billingName: "Bagmane Group",
                      total: "₹ 1,72,000",
                      status: <div className="badge badge-soft-success font-size-12">Paid</div>,
                  },
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#CF1571</Link>,
                      date: "02 Nov, 2022",
                      billingName: "IHCL",
                      total: "₹ 1251,000",
                      status: <div className="badge badge-soft-warning font-size-12">unpaid</div>,
                  },
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#CF1570</Link>,
                      date: "01 Nov, 2022",
                      billingName: "Blu Smart",
                      total: "₹ 146,000,",
                      status: <div className="badge badge-soft-success font-size-12">Paid</div>,
                  },
                  {
                      id: 4,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#CF1569</Link>,
                      date: "01 Nov, 2022",
                      billingName: "Phoenix",
                      total: "₹ 907,200",
                      status: <div className="badge badge-soft-success font-size-12">Paid</div>,
                  },
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#CF1568</Link>,
                      date: "01 Nov, 2022",
                      billingName: "Lalit Group",
                      total: "₹ 8100",
                      status: <div className="badge badge-soft-danger font-size-12">Chargeback</div>,
                  },
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#CF1567</Link>,
                      date: "03 Nov, 2021",
                      billingName: "Anirudh Harish",
                      total: "₹ 54",
                      status: <div className="badge badge-soft-warning font-size-12">unpaid</div>,
                  },
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#CF1566</Link>,
                      date: "03 Nov, 2021",
                      billingName: "Walter Golmes",
                      total: "₹ 252",
                      status: <div className="badge badge-soft-success font-size-12">Paid</div>,
                  },
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1565</Link>,
                      date: "03 Nov, 2021",
                      billingName: "Abdul Aleim",
                      total: "₹ 306",
                      status: <div className="badge badge-soft-success font-size-12">Paid</div>,
                  },
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1564</Link>,
                      date: "03 Nov, 2021",
                      billingName: "Abinav Sankar",
                      total: "₹ 117",
                      status: <div className="badge badge-soft-success font-size-12">Paid</div>,
                  },
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1563</Link>,
                      date: "03 Nov, 2021",
                      billingName: "Aditya Thackeray",
                      total: "₹ 27",
                      status: <div className="badge badge-soft-warning font-size-12">unpaid</div>,
                  },
                  {
                      id: 1,
                      orderId: <Link to="#" className="text-dark font-weight-bold">#NZ1562</Link>,
                      date: "03 Nov, 2021",
                      billingName: "Sachin Ahir",
                      total: "₹ 90",
                      status: <div className="badge badge-soft-success font-size-12">Paid</div>,
                  },
              ]
          }; */

    /*  const fault_data = {
             columns: [
                 {
                     dataField: 'connId',
                     text: 'Conn'
                 },
                 {
                     dataField: "chargerId",
                     text: "Charger ID"
                 },
                 {
                     dataField: "timeStamp",
                     text: "Date & Time"
                 },
                 {
                     dataField: "details",
                     text: "Details"
                 }
             ]
         }; */

    const data = {
      columns: [
        {
          label: (
            <div className="custom-control custom-checkbox">
              {" "}
              <Input
                type="checkbox"
                className="custom-control-input"
                id="ordercheck"
              />
              <Label className="custom-control-label" htmlFor="ordercheck">
                &nbsp;
              </Label>
            </div>
          ),
          field: "checkbox",
          sort: "asc",
          width: 28,
        },
        {
          label: "Charger ID",
          field: "id",
          sort: "asc",
          width: 78,
        },
        {
          label: "Conn ID",
          field: "conn",
          sort: "asc",
          width: 28,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 93,
        },
        {
          label: "User Id",
          field: "userid",
          sort: "asc",
          width: 109,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 28,
        },
        {
          label: "Units",
          field: "kWh",
          sort: "asc",
          width: 28,
        },
        {
          label: "Total",
          field: "total",
          sort: "asc",
          width: 48,
        },
        {
          label: "Payment Status",
          field: "status",
          sort: "asc",
          width: 135,
        },
        {
          label: "Invoice",
          field: "invoice",
          sort: "asc",
          width: 110,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 120,
        },
      ],
      rows: this.state.transLog,
      /*  rows: [
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck1" />
                             <Label className="custom-control-label" htmlFor="ordercheck1">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1572</Link>,
                     date: "21 Oct, 2021",
                     userid: "Ashok.Kumar",
                     conn: "1",
                     kWh: "9",
                     total: "₹ 90",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit1"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit1">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete1"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete1">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck2" />
                             <Label className="custom-control-label" htmlFor="ordercheck2">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1571</Link>,
                     date: "21 Oct, 2021",
                     userid: "Jon.Doe1",
                     total: "₹ 100",
                     conn: "2",
                     kWh: "10",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit2"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit2">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete2"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete2">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck3" />
                             <Label className="custom-control-label" htmlFor="ordercheck3">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1570</Link>,
                     date: "21 Oct, 2021",
                     userid: "JaneDoe1",
                     total: "₹ 200",
                     conn: "2",
                     kWh: "20",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit3"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit3">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete3"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete3">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck4" />
                             <Label className="custom-control-label" htmlFor="ordercheck4">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1569</Link>,
                     date: "21 Oct, 2021",
                     userid: "David.Gilmour",
                     total: "₹ 300",
                     conn: "1",
                     kWh: "15",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit4"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit4">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete5"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete5">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck6" />
                             <Label className="custom-control-label" htmlFor="ordercheck6">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1568</Link>,
                     date: "21 Oct, 2021",
                     userid: "Roger.Waters",
                     total: "₹ 250",
                     conn: "2",
                     kWh: "12",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit6"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit6">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete6"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete6">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck7" />
                             <Label className="custom-control-label" htmlFor="ordercheck7">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1567</Link>,
                     date: "21 Oct, 2021",
                     userid: "Gordon.Sumner",
                     total: "₹ 72",
                     conn: "2",
                     kWh: "7.2",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit7"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit7">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete7"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete7">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck8" />
                             <Label className="custom-control-label" htmlFor="ordercheck8">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1566</Link>,
                     date: "21 Oct, 2021",
                     userid: "Michael.Riley",
                     total: "₹ 500",
                     conn: "1",
                     kWh: "25",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit9"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit9">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete9"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete9">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck10" />
                             <Label className="custom-control-label" htmlFor="ordercheck10">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1565</Link>,
                     date: "21 Oct, 2021",
                     userid: "Ashoka.Kumari",
                     total: "₹ 20",
                     conn: "2",
                     kWh: "2",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit10"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit10">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete10"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete10">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck11" />
                             <Label className="custom-control-label" htmlFor="ordercheck11">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1564</Link>,
                     date: "21 Oct, 2021",
                     userid: "Gibbs",
                     total: "₹ 210",
                     conn: "2",
                     kWh: "21",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit11"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit11">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete11"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete11">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck12" />
                             <Label className="custom-control-label" htmlFor="ordercheck12">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF1563</Link>,
                     date: "20 Oct, 2021",
                     userid: "Mbappe",
                     total: "₹ 180",
                     conn: "2",
                     kWh: "9",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit12"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit12">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete12"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete12">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 },
                 {
                     checkbox:
                         <div className="custom-control custom-checkbox">
                             <Input type="checkbox" className="custom-control-input" id="ordercheck13" />
                             <Label className="custom-control-label" htmlFor="ordercheck13">&nbsp;</Label>
                         </div>,
                     id: <Link to="#" className="text-dark font-weight-bold">#CF15632</Link>,
                     date: "20 Oct, 2021",
                     userid: "SRT",
                     total: "₹ 306",
                     conn: "1",
                     kWh: "15.3",
                     status: <div className="badge badge-soft-success font-size-12">Pre-paid</div>,
                     invoice: <Button className="btn-rounded" color="light">Invoice <i className="mdi mdi-download ml-2"></i></Button>,
                     action: <><Link to="#" className="mr-3 text-primary" id="edit13"><i className="mdi mdi-pencil font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="edit13">
                             Edit
                         </UncontrolledTooltip >
                         <Link to="#" className="text-danger" id="delete13"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                         <UncontrolledTooltip placement="top" target="delete13">
                             Delete
                         </UncontrolledTooltip >
                     </>
                 }] */
    };

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList: [
        {
          text: "5th",
          value: 5,
        },
        {
          text: "10th",
          value: 10,
        },
        {
          text: "All",
          value: this.state.transLog.length,
        },
      ],
    };

    const optionsFault = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList: [
        {
          text: "5th",
          value: 5,
        },
        {
          text: "10th",
          value: 10,
        },
        {
          text: "All",
          value: this.state.faultLog.length,
        },
      ],
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
    };

    const start_click = () => {
      if (this.state.chargerStatus === "Offline") {
        alert("Charger is Offline");
        return;
      }

      if (this.state.chargerStatus !== "Charging") {
        /* console.log("Socket Send Topic", this.getRequestTopic(this.state.chargerId));
 
                console.log("Socket Send Payload", JSON.stringify({
                    type: "remoteStart",
                    idTag: "9902062209",
                    connectorId: 1
                })); */

        this.socketSend(this.getRequestTopic(this.state.chargerId), {
          type: "remoteStart",
          idTag: "9902062209",
          connectorId: 1,
        });
      }
    };

    const stop_click = () => {
      //console.log("Stop Charge TransactionId", this.state.ActiveTransactionId);
      if (this.state.ActiveTransactionId === 0) {
        alert("Not Authorized to stop this transaction");
        return;
      }

      if (this.state.chargerStatus === "Offline") {
        alert("Charge is Offline");
        return;
      }

      this.socketSend(this.getRequestTopic(this.state.chargerId), {
        type: "remoteStop",
        transactionId: this.state.ActiveTransactionId,
      });
    };

    //let meter_value = 0.0;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Charger Details"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={5}>
                        <div className="product-detail">
                          <Row>
                            <Col md={8} xs={9}>
                              <TabContent
                                activeTab={this.state.activeTab}
                                id="v-pills-tabContent"
                              >
                                <TabPane tabId="1">
                                  <div className="product-img">
                                    <img
                                      src={this.state.imgSrc}
                                      id="expandedImg1"
                                      style={{ height: 200 }}
                                      alt=""
                                      className="img-fluid mx-auto d-block rounded"
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="2">
                                  <div className="product-img">
                                    <img
                                      src={this.state.imgSrc}
                                      id="expandedImg2"
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="3">
                                  <div className="product-img">
                                    <img
                                      src={this.state.imgSrc}
                                      id="expandedImg3"
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="4">
                                  <div className="product-img">
                                    <img
                                      src={this.state.imgSrc}
                                      id="expandedImg4"
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                              </TabContent>
                              {/* {<Row className="text-center mt-2">
                                                                <Col sm={6}>
                                                                    <Button onClick={start_click} style={{ "backgroundColor": this.state.start_btn_clr, "color": this.state.start_btn_txt_clr }}
                                                                        color="primary" block type="button" className="waves-effect waves-light mt-2 mr-1">
                                                                        <i className="mdi mr-2"></i> Start Charge
                                                                    </Button>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Button onClick={stop_click} style={{ "backgroundColor": this.state.stop_btn_clr, "color": this.state.stop_btn_txt_clr }}
                                                                        color="light" block type="button" className="btn-block waves-effect  mt-2 waves-light">
                                                                        <i className="mdi mr-2"></i>Stop Charge
                                                                    </Button>
                                                                </Col>
                                                            </Row>} */}
                              {/*  <br></br>
                                                            <h5 className="font-size-14 mb-3"><b>Ocpp Id:</b> {this.state.ocppId}</h5>
                                                            <h5 className="font-size-14 mb-3"><b>Transaction Id:</b> {this.state.ActiveTransactionId}</h5>
                                                            <h5 className="font-size-14 mb-3"><b>Consumption(in kWh):</b> {this.state.consumption}</h5>
 */}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xl={7}>
                        <div className="mt-4 mt-xl-3">
                          <Link to="#" className="text-primary">
                            Email Id: {this.props.location.email}
                          </Link>
                          <h5 className="mt-1 mb-3">
                            {this.props.location.user}
                          </h5>

                          {/* <div className="d-inline-flex">
                                                        <div className="text-muted mr-3">
                                                            <span className="mdi mdi-star text-warning mr-1"></span>
                                                            <span className="mdi mdi-star text-warning mr-1"></span>
                                                            <span className="mdi mdi-star text-warning mr-1"></span>
                                                            <span className="mdi mdi-star text-warning mr-1"></span>
                                                            <span className="mdi mdi-star mr-1"></span>
                                                        </div>
                                                        <div className="text-muted">( 132 )</div>
    </div>*/}

                          {/* <h5 className="mt-2"><span className={this.renderStatus()}>{this.state.chargerStatus}</span> <span className="text-danger font-size-12 ml-2"> {"<"} 5% Downtime</span></h5> */}
                          {/*<p className="mt-3">To achieve this, it would be necessary to have uniform pronunciation</p>*/}
                          <hr className="my-4" />

                          <Row>
                            <Col>
                              <div>
                                {/* <h5 className="font-size-14"><i className="mdi mdi-location"></i> Delivery location</h5>
                                                                <div className="form-inline">

                                                                    <InputGroup className="mb-3">
                                                                        <Input type="text" className="form-control" placeholder="Enter Delivery pincode " />
                                                                        <InputGroupAddon addonType="append">
                                                                            <Button color="light" type="button">Check</Button>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </div> */}

                                <h5 className="font-size-14">About User</h5>
                                <ul className="list-unstyled product-desc-list">
                                  <li>
                                    <i className="mdi mdi-sync text-primary mr-1 align-middle font-size-16"></i>{" "}
                                    <b>Contact: </b>
                                    {this.props.location.phone}
                                  </li>
                                  <li>
                                    <i className="mdi mdi-sync text-primary mr-1 align-middle font-size-16"></i>{" "}
                                    <b>Email: </b>
                                    <Link>{this.props.location.email}</Link>
                                  </li>
                                  <li>
                                    <i className="mdi mdi-currency-usd-circle text-primary mr-1 align-middle font-size-16"></i>{" "}
                                    <b>Location Id: </b>
                                    <Link>#141</Link>
                                  </li>
                                  <li>
                                    <i className="mdi mdi-currency-usd-circle text-primary mr-1 align-middle font-size-16"></i>{" "}
                                    <b>Location Name: </b>L&T{" "}
                                  </li>
                                  <li>
                                    <i className="mdi mdi-currency-usd-circle text-primary mr-1 align-middle font-size-16"></i>{" "}
                                    <b>Ref: </b> NA{" "}
                                  </li>
                                  <li>
                                    <i className="mdi mdi-currency-usd-circle text-primary mr-1 align-middle font-size-16"></i>{" "}
                                    <b>Rfid Cards: </b>NA{" "}
                                  </li>
                                </ul>

                                {/*  <h5 className="font-size-14"><i className="mdi mdi-location"></i> Firmware Upgrade</h5>
                                                                <div className="form-inline">

                                                                    <InputGroup className="mb-3">
                                                                        <Input type="text" className="form-control" placeholder="URL " />
                                                                        <InputGroupAddon addonType="append">
                                                                            <Button color="light" type="button">Upgrade</Button>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </div>
 */}
                              </div>
                            </Col>

                            <div>
                              {/*  <h5 className="font-size-14">User</h5>
                                                            <ul className="list-unstyled product-desc-list">
                                                                <li><i className="mdi align-middle"></i> 99020 62291</li>
                                                                <li><i className="mdi align-middle"></i> raju.pillai@ltebg.com</li>
                                                                <li><i className="mdi align-middle"></i> 0001</li>
                                                                <li><i className="mdi align-middle"></i>L&T Electrical & Automation</li>
                                                            </ul> */}
                            </div>
                          </Row>

                          <Row>
                            {/* <Col md={6}>
                                                            <div className="product-color mt-3">
                                                                <h5 className="font-size-14">Color :</h5>
                                                                <Link to="#" className="active">
                                                                    <div className="product-color-item">
                                                                        <img src={img1} alt="" className="avatar-md"/>
                                                                    </div>
                                                                    <p>Blue</p>
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <img src={img5} alt="" className="avatar-md"/>
                                                                    </div>
                                                                    <p>Cyan</p>
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <img src={img3} alt="" className="avatar-md"/>
                                                                    </div>
                                                                    <p>Green</p>
                                                                </Link>
                                                            </div>
                                                        </Col> 

                                                        <Col md={6}>
                                                            <div className="product-color mt-3">
                                                                <h5 className="font-size-14">Size :</h5>
                                                                <Link to="#" className="active ml-1">
                                                                    <div className="product-color-item">
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title bg-transparent text-body">S</span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title bg-transparent text-body">M</span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title bg-transparent text-body">L</span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                <Link to="#" className="ml-1">
                                                                    <div className="product-color-item">
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title bg-transparent text-body">XL</span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </Col>*/}
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-4">
                      {/* <h5 className="font-size-14 mb-3"><b>Consumption(in kWh): {this.state.consumption}</b></h5> */}
                      {/* <h5 className="font-size-14 mb-3">Logs: </h5> */}

                      <MDBDataTable responsive data={data} className="mt-4" />
                    </div>

                    {/* <div className="mt-4">
                                            <h5 className="font-size-14">Reviews : </h5>
                                            <div className="d-inline-flex mb-3">
                                                <div className="text-muted mr-3">
                                                    <span className="mdi mdi-star text-warning mr-1"></span>
                                                    <span className="mdi mdi-star text-warning mr-1"></span>
                                                    <span className="mdi mdi-star text-warning mr-1"></span>
                                                    <span className="mdi mdi-star text-warning mr-1"></span>
                                                    <span className="mdi mdi-star mr-1"></span>
                                                </div>
                                                <div className="text-muted">( 132  customer Review)</div>
                                            </div>
                                            <div className="border p-4 rounded">
                                                <Media className="border-bottom pb-3">
                                                    <Media body>
                                                        <p className="text-muted mb-2">To an English person, it will seem like simplified English, as a skeptical Cambridge</p>
                                                        <h5 className="font-size-15 mb-3">James</h5>

                                                        <ul className="list-inline product-review-link mb-0">
                                                            <li className="list-inline-item">
                                                                <Link to="#"><i className="mdi mdi-thumb-up align-middle mr-1"></i> Like</Link>
                                                            </li>
                                                            <li className="list-inline-item ml-1">
                                                                <Link to="#"><i className="mdi mdi-message-text align-middle mr-1"></i> Comment</Link>
                                                            </li>
                                                        </ul>
                                                    </Media>
                                                    <p className="float-sm-right font-size-12">11 Feb, 2020</p>
                                                </Media>
                                                <Media className="border-bottom py-3">
                                                    <Media body>
                                                        <p className="text-muted mb-2">Everyone realizes why a new common language would be desirable</p>
                                                        <h5 className="font-size-15 mb-3">David</h5>

                                                        <ul className="list-inline product-review-link mb-0">
                                                            <li className="list-inline-item">
                                                                <Link to="#"><i className="mdi mdi-thumb-up align-middle mr-1"></i> Like</Link>
                                                            </li>
                                                            <li className="list-inline-item ml-1">
                                                                <Link to="#"><i className="mdi mdi-message-text align-middle mr-1"></i> Comment</Link>
                                                            </li>
                                                        </ul>
                                                    </Media>
                                                    <p className="float-sm-right font-size-12">22 Jan, 2020</p>
                                                </Media>
                                                <Media className="py-3">
                                                    <Media body>
                                                        <p className="text-muted mb-2">If several languages coalesce, the grammar of the resulting </p>
                                                        <h5 className="font-size-15 mb-3">Scott</h5>

                                                        <ul className="list-inline product-review-link mb-0">
                                                            <li className="list-inline-item">
                                                                <Link to="#"><i className="mdi mdi-thumb-up align-middle mr-1"></i> Like</Link>
                                                            </li>
                                                            <li className="list-inline-item ml-1">
                                                                <Link to="#"><i className="mdi mdi-message-text align-middle mr-1"></i> Comment</Link>
                                                            </li>
                                                        </ul>
                                                    </Media>
                                                    <p className="float-sm-right font-size-12">04 Jan, 2020</p>
                                                </Media>
                                            </div>
                                        </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={4}>
                                                <Media>
                                                    <div className="avatar-sm mr-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-checkbox-circle-line"></i>
                                                        </span>
                                                    </div>
                                                    <Media body className="align-self-center overflow-hidden">
                                                        <h5>Free Shipping</h5>
                                                        <p className="text-muted mb-0">Sed ut perspiciatis unde</p>
                                                    </Media>
                                                </Media>
                                            </Col>
                                            <Col md={4}>
                                                <Media className="mt-4 mt-md-0">
                                                    <div className="avatar-sm mr-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-exchange-line"></i>
                                                        </span>
                                                    </div>
                                                    <Media body className="align-self-center overflow-hidden">
                                                        <h5>Easy Return</h5>
                                                        <p className="text-muted mb-0">Neque porro quisquam est</p>
                                                    </Media>
                                                </Media>
                                            </Col>
                                            <Col md={4}>
                                                <Media className="mt-4 mt-md-0">
                                                    <div className="avatar-sm mr-3">
                                                        <span className="avatar-title bg-light rounded-circle text-primary font-size-24">
                                                            <i className="ri-money-dollar-circle-line"></i>
                                                        </span>
                                                    </div>
                                                    <Media body className="align-self-center overflow-hidden">
                                                        <h5>Cash on Delivery</h5>
                                                        <p className="text-muted mb-0">Ut enim ad minima quis</p>
                                                    </Media>
                                                </Media>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                                    </Row> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedUserId: state.ChargerManagement.selectedUserId,
});

const mapDispatchToProps = (dispatch) => ({
  chargerSelected: (userId) => dispatch(chargerSelected(userId)),
});

//connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);

//export default ProductDetail;
