
export const linkify_fault_log = (fault_log) => {

    if(!fault_log)
        return "";

    let faultExp = new RegExp("[ESBPressed]+","i");

    const outputLog = fault_log.split(faultExp).join("<Link to=\"#\" className=\"text-dark font-weight-bold\">ESBPressed</Link>");

    return outputLog;

}