import React, { Component } from "react";
import { Collapse, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";

class ChargerFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [44, 55, 67, 83],
      options: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 249;
                },
              },
            },
          },
        },

        labels: ["Computer", "Tablet", "Laptop", "Mobile"],
        colors: ["#556ee6", "#34c38f", "#f46a6a", "#f1b44c"],
      },
    };
  }

  fData = {
    manufacturer: [],
    connectors: [],
    rating: [],
    Type: [],
  };

  handleApply = () => {
    try {
      if (this.props.results !== null) this.props.results(this.fData);
    } catch (e) {
      console.log("Filter Results Error", e);
    }
  };

  addValue = (value) => {
    if (!value) return;

    if (value.connector) {
      if (this.fData.connectors.indexOf(value.connector) === -1)
        // Check if entry exists
        this.fData.connectors.push(value.connector);

      this.fData.connectors.sort();
      // console.log("fData", this.fData);
      return;
    }

    if (value.manufacturer) {
      if (this.fData.manufacturer.indexOf(value.manufacturer) === -1)
        // Check if entry exists
        this.fData.manufacturer.push(value.manufacturer);

      this.fData.manufacturer.sort();
      //console.log("fData", this.fData);
      return;
    }

    if (value.Type) {
      if (this.fData.Type.indexOf(value.Type) === -1)
        // Check if entry exists
        this.fData.Type.push(value.Type);

      this.fData.Type.sort();
      // console.log("fData", this.fData);
      return;
    }

    if (value.rating) {
      if (this.fData.rating.indexOf(value.rating) === -1)
        // Check if entry exists
        this.fData.rating.push(value.rating);

      this.fData.rating.sort();
      //console.log("fData", this.fData);
      return;
    }
  };

  removeValue = (value) => {
    if (!value) return;

    if (value.connector) {
      let i = this.fData.connectors.indexOf(value.connector);

      if (i !== -1)
        // Check if entry exists
        this.fData.connectors.splice(i, 1);

      //  console.log("fData", this.fData);
      return;
    }

    if (value.manufacturer) {
      let i = this.fData.manufacturer.indexOf(value.manufacturer);

      if (i !== -1)
        // Check if entry exists
        this.fData.manufacturer.splice(i, 1);

      //console.log("fData", this.fData);
      return;
    }

    if (value.Type) {
      let i = this.fData.Type.indexOf(value.Type);

      if (i !== -1)
        // Check if entry exists
        this.fData.Type.splice(i, 1);

      //console.log("fData", this.fData);
      return;
    }

    if (value.rating) {
      let i = this.fData.rating.indexOf(value.rating);

      if (i !== -1)
        // Check if entry exists
        this.fData.rating.splice(i, 1);

      //console.log("fData", this.fData);
      return;
    }
  };

  render() {
    return (
      <React.Fragment>
        <h5 className="font-size-14 mb-3">Categories</h5>

        <div
          id="accordion"
          className="custom-accordion categories-accordion mb-3"
        >
          <div className="categories-group-card">
            <Link
              to="#"
              onClick={() =>
                this.setState({ electronic: !this.state.electronic })
              }
              className={
                this.state.electronic
                  ? "categories-group-list accordian-bg-products"
                  : "categories-group-list"
              }
            >
              <i className="mdi mdi-current-ac font-size-16 align-middle mr-2"></i>{" "}
              Manufacturer
              <i
                className={
                  this.state.electronic === true
                    ? "mdi mdi-minus float-right accor-minus-icon"
                    : "mdi mdi-plus float-right accor-plus-icon"
                }
              ></i>
            </Link>

            <Collapse isOpen={this.state.electronic} id="collapseOne">
              <div>
                <ul className="list-unstyled categories-list mb-0">
                  {/*<li><Link to="#" onClick={() => this.props.filterChargers("3.3","1")}><i className="mdi mdi-circle-medium mr-1"></i> 3.3kW IEC 60309, Single-socket</Link></li>
                                                            <li><Link to="#" onClick={() => this.props.filterChargers("3.3","1")}><i className="mdi mdi-circle-medium mr-1"></i> 3.3kW Plug-socket, Single-socket</Link></li>
                                                            <li><Link to="#" onClick={() => this.props.filterChargers("7.2","1")}><i className="mdi mdi-circle-medium mr-1"></i> 7.2kW Type-2, Single-connector</Link></li>
                                                            <li><Link to="#" onClick={() => this.props.filterChargers("14.4","2")}><i className="mdi mdi-circle-medium mr-1"></i> 14.4kW Type-2, Multi-connector</Link></li>
                                                            <li><Link to="#" onClick={() => this.props.filterChargers("11","1")}><i className="mdi mdi-circle-medium mr-1"></i> 11kW Type-2, Single-connector</Link></li>
        <li><Link to="#" onClick={() => this.props.filterChargers("22","1")}><i className="mdi mdi-circle-medium mr-1"></i> 22kW Type-2, Single-connector</Link></li>*/}
                  {this.props.manufacturers.length > 0 && (
                    <div className="form-check mb-3 ml-3">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        All
                      </Label>
                    </div>
                  )}

                  {this.props.manufacturers.map((manufacturer) => (
                    <div
                      className="form-check mb-3 ml-3"
                      key={manufacturer.name}
                    >
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck2"
                        onChange={(event) => {
                          event.target.checked
                            ? this.addValue({ manufacturer: manufacturer.name })
                            : this.removeValue({
                                manufacturer: manufacturer.name,
                              });
                        }}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck2"
                      >
                        {manufacturer.name}
                      </Label>
                    </div>
                  ))}

                  {/*<div className="form-check mb-3 ml-3">
                                                            <Input className="form-check-input" type="checkbox" value="" id="defaultCheck3"  />
                                                            <Label className="form-check-label" htmlFor="defaultCheck3">
                                                                M1
                                                            </Label>
                                                        </div>*/}
                </ul>
              </div>
            </Collapse>
          </div>

          <div className="categories-group-card">
            <Link
              to="#"
              className={
                this.state.fashion
                  ? "categories-group-list accordian-bg-products"
                  : "categories-group-list"
              }
              onClick={() => this.setState({ fashion: !this.state.fashion })}
            >
              <i className="mdi mdi-current-dc font-size-16 align-middle mr-2"></i>{" "}
              Type
              <i
                className={
                  this.state.fashion === true
                    ? "mdi mdi-minus float-right accor-minus-icon"
                    : "mdi mdi-plus float-right accor-plus-icon"
                }
              ></i>
            </Link>
            <Collapse isOpen={this.state.fashion} id="collapseTwo">
              <div>
                <ul className="list-unstyled categories-list mb-0">
                  <div className="form-check mb-3 ml-3">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                      onChange={(event) => {
                        event.target.checked
                          ? this.addValue({ Type: "AC" })
                          : this.removeValue({ Type: "AC" });
                      }}
                    />
                    <Label className="form-check-label" htmlFor="defaultCheck1">
                      AC
                    </Label>
                  </div>
                  <div className="form-check mb-3 ml-3">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck2"
                      onChange={(event) => {
                        event.target.checked
                          ? this.addValue({ Type: "DC" })
                          : this.removeValue({ Type: "DC" });
                      }}
                    />
                    <Label className="form-check-label" htmlFor="defaultCheck2">
                      DC
                    </Label>
                  </div>
                  <div className="form-check mb-3 ml-3">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck3"
                      onChange={(event) => {
                        event.target.checked
                          ? this.addValue({ Type: "Combo" })
                          : this.removeValue({ Type: "Combo" });
                      }}
                    />
                    <Label className="form-check-label" htmlFor="defaultCheck3">
                      Combo
                    </Label>
                  </div>
                </ul>
              </div>
            </Collapse>
          </div>

          <div className="categories-group-card">
            <Link
              to="#"
              onClick={() => this.setState({ fitness: !this.state.fitness })}
              className={
                this.state.fitness
                  ? "categories-group-list accordian-bg-products"
                  : "categories-group-list"
              }
            >
              <i className="mdi mdi-all-inclusive font-size-16 align-middle mr-2"></i>{" "}
              Power (kW)
              <i
                className={
                  this.state.fitness === true
                    ? "mdi mdi-minus float-right accor-minus-icon"
                    : "mdi mdi-plus float-right accor-plus-icon"
                }
              ></i>
            </Link>
            <Collapse isOpen={this.state.fitness} id="collapseFour">
              <div>
                <ul className="list-unstyled categories-list mb-0">
                  {this.props.powerRatings.length > 0 && (
                    <div className="form-check mb-3 ml-3">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        All
                      </Label>
                    </div>
                  )}

                  {this.props.powerRatings.map((rating) => (
                    <div className="form-check mb-3 ml-3">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        value={rating}
                        id="defaultCheck1"
                        onChange={(event) => {
                          event.target.checked
                            ? this.addValue({ rating })
                            : this.removeValue({ rating });
                        }}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        {rating}
                      </Label>
                    </div>
                  ))}
                </ul>
              </div>
            </Collapse>
          </div>

          <div className="categories-group-card">
            <Link
              to="#"
              onClick={() => this.setState({ fitness: !this.state.fitness })}
              className={
                this.state.fitness
                  ? "categories-group-list accordian-bg-products"
                  : "categories-group-list"
              }
            >
              <i className="mdi mdi-all-inclusive font-size-16 align-middle mr-2"></i>{" "}
              Connectors
              <i
                className={
                  this.state.fitness === true
                    ? "mdi mdi-minus float-right accor-minus-icon"
                    : "mdi mdi-plus float-right accor-plus-icon"
                }
              ></i>
            </Link>
            <Collapse isOpen={this.state.fitness} id="collapseFour">
              <div>
                <ul className="list-unstyled categories-list mb-0">
                  <div className="form-check mb-3 ml-3">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                      onChange={(event) => {
                        event.target.checked
                          ? this.addValue({ connector: "1" })
                          : this.removeValue({ connector: "1" });
                      }}
                    />
                    <Label className="form-check-label" htmlFor="defaultCheck1">
                      1
                    </Label>
                  </div>
                  <div className="form-check mb-3 ml-3">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck2"
                      onChange={(event) => {
                        event.target.checked
                          ? this.addValue({ connector: "2" })
                          : this.removeValue({ connector: "2" });
                      }}
                    />
                    <Label className="form-check-label" htmlFor="defaultCheck2">
                      2
                    </Label>
                  </div>
                  <div className="form-check mb-3 ml-3">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck3"
                      onChange={(event) => {
                        event.target.checked
                          ? this.addValue({ connector: "3" })
                          : this.removeValue({ connector: "3" });
                      }}
                    />
                    <Label className="form-check-label" htmlFor="defaultCheck3">
                      3
                    </Label>
                  </div>
                </ul>
              </div>
            </Collapse>
          </div>

          <div className="mt-2 text-center">
            <Button
              color="primary"
              className="w-md waves-effect waves-light"
              onClick={() => this.handleApply()}
            >
              Apply
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChargerFilter;
