import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Table, Collapse, CardHeader, 
    UncontrolledTooltip, Alert, Input, Label, Modal, ModalHeader, ModalBody, 
    ModalFooter, Button, FormGroup, InputGroup, InputGroupAddon } from "reactstrap";
import { Link } from "react-router-dom";

import { renderStatus } from '../../helpers/render_helper';

import ChargerFilter from '../../components/CommonForBoth/ChargerFilter';

import io from 'socket.io-client';

import { getRequestTopic, getResponseTopic } from '../../helpers/topic_Management';

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

let socket;

let chargers = [
    {
        chargerId: "2C484CEF49C0",
        type: "AC",
        power: "3.3",
        connectors: "1",
        firmware: "1.20",
        status: "Offline",
        manufacturer: "i-Pec"
    },
    {
        chargerId: "744A4CEF49C0",
        type: "AC",
        power: "7.2",
        connectors: "1",
        firmware: "1.10",
        status: "Offline",
        manufacturer: "i-Pec"
    },
    {
        chargerId: "A84A4CEF49C0",
        type: "AC",
        power: "22",
        connectors: "1",
        firmware: "1.00",
        status: "Offline",
        manufacturer: "i-Pec"
    }

];

class FirmwareMgmt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Charger Management System", link: "#" },
                { title: "Users", link: "#" },
            ],
            isAlertOpen: false,
            modal_static: false,
            data: chargers
        }
        this.addCustomer.bind(this);
    }

    async componentDidMount(){

        try{
            socket = io("https://backend.iqlabs.io:3000");

            socket.on("connect", () => {
                console.log("Socket Connection Successful");

               
                chargers.forEach(charger => {
                    console.log("Subscribed Topics", getResponseTopic(charger.chargerId));
                    socket.emit("subscribe", JSON.stringify({ topic: getResponseTopic(charger.chargerId) }));
                });

                }
            )

            socket.on("mqtt", msg => {

                if (msg == null)
                    return;

                //console.log("Rx Msg", msg);
                /* const msgJson = JSON.parse(msg);
                const topic = msgJson.topic;
                const payload = JSON.parse(msgJson.payload);

                this.processMsg(payload); */


            });

        }
        catch(e){

        }

    }

    async componentWillUnmount(){

        if(!socket)
            return;

        chargers.forEach(charger => {
            socket.emit("unsubscribe", JSON.stringify({ topic: getResponseTopic(charger.chargerId) }));
        });

        socket.disconnect();
        
    }

    addCustomer = (event, values) => {
        //Assign values to the variables
        var name = values.custname;
        var cEmail = values.custemail;
        var phonenumber = values.phonenumber;
        var newRfid = "$ " + values.rfid;
        var d = new Date();
        var day = d.getDate();
        var mon = d.getMonth();
        var y = d.getFullYear();
        var date = day + "/" + mon + "/" + y;
        let demoData = this.state.data;

        //push data to the varible
        demoData.push({ customer: name, email: cEmail, phone: phonenumber, rfid: newRfid, date: date })

        //update data state
        this.setState({ data: demoData });

        //show alert for success message
        this.setState({ isAlertOpen: true });

        //update state for closing
        setTimeout(() => {
            this.setState({ modal_static: false });
        }, 2000);
    }



    filterChargers = (filterParams) => {


        if(!filterParams)
            return;

       

        if(filterParams.length <= 0)
            return;

        let result = chargers.filter(charger=> 
            (this.isCriteriaPass(filterParams.manufacturer, charger.manufacturer)) &&
            (this.isCriteriaPass(filterParams.connectors,charger.connectors)) &&
            (this.isCriteriaPass(filterParams.rating, charger.power)) &&
            (this.isCriteriaPass(filterParams.Type, charger.type))
        );

        console.log("Filtered Chargers", result);

        this.setState({data:result});


    }

    isCriteriaPass = (arr,val) => {
        if(!val)
            return false;

        if(!Array.isArray(arr))
            return false;

        if(val.toLowerCase() === "all")
            return true;

        return (arr.indexOf(val) !== -1)
    }

    getFilteredResults = (results) => {
        console.log("Filtered Results", results);
        
        this.filterChargers(results);

    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Advanced Settings" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>

                        <Col xl={3} lg={4}>
                                <Card>
                                    <CardHeader className="bg-transparent border-bottom">
                                        <h5 className="mb-0">Filters</h5>
                                       
                                    </CardHeader>
                                {/*
                                    <CardBody>
                                        <h5 className="font-size-14 mb-3">Categories</h5>

                                        <div id="accordion" className="custom-accordion categories-accordion mb-3">
                                            <div className="categories-group-card">
                                                <Link to="#" onClick={() => this.setState({ electronic: !this.state.electronic, fashion: false, baby: false, fitness: false })} className={this.state.electronic ? "categories-group-list accordian-bg-products" : "categories-group-list"}>
                                                    <i className="mdi mdi-current-ac font-size-16 align-middle mr-2"></i> AC Chargers
                                                    <i className={this.state.electronic === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i>
                                                </Link>

                                                <Collapse isOpen={this.state.electronic} id="collapseOne">
                                                    <div>
                                                        <ul className="list-unstyled categories-list mb-0">
                                                            <li><Link to="#" onClick={() => this.filterChargers("3.3","1")}><i className="mdi mdi-circle-medium mr-1"></i> 3.3kW IEC 60309, Single-socket</Link></li>
                                                            <li><Link to="#" onClick={() => this.filterChargers("3.3","1")}><i className="mdi mdi-circle-medium mr-1"></i> 3.3kW Plug-socket, Single-socket</Link></li>
                                                            <li><Link to="#" onClick={() => this.filterChargers("7.2","1")}><i className="mdi mdi-circle-medium mr-1"></i> 7.2kW Type-2, Single-connector</Link></li>
                                                            <li><Link to="#" onClick={() => this.filterChargers("14.4","2")}><i className="mdi mdi-circle-medium mr-1"></i> 14.4kW Type-2, Multi-connector</Link></li>
                                                            <li><Link to="#" onClick={() => this.filterChargers("11","1")}><i className="mdi mdi-circle-medium mr-1"></i> 11kW Type-2, Single-connector</Link></li>
                                                            <li><Link to="#" onClick={() => this.filterChargers("22","1")}><i className="mdi mdi-circle-medium mr-1"></i> 22kW Type-2, Single-connector</Link></li>
                                                        </ul>
                                                    </div>
                                                </Collapse>
                                            </div>

                                            <div className="categories-group-card">
                                                <Link to="#" className={this.state.fashion ? "categories-group-list accordian-bg-products" : "categories-group-list"} onClick={() => this.setState({ fashion: !this.state.fashion, electronic: false, baby: false, fitness: false })}>
                                                    <i className="mdi mdi-current-dc font-size-16 align-middle mr-2"></i> DC Chargers
                                                    <i className={this.state.fashion === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i>
                                                </Link>
                                                <Collapse isOpen={this.state.fashion} id="collapseTwo">
                                                    <div>
                                                        <ul className="list-unstyled categories-list mb-0">
                                                            <li className="active"><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 30kW CCS2, Single-gun</Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 60kW CCS2, Double-gun</Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 120kW CCS2, Double-gun</Link></li>
                                                        </ul>
                                                    </div>
                                                </Collapse>
                                            </div>

                                           

                                            <div className="categories-group-card">
                                                <Link to="#" onClick={() => this.setState({ fitness: !this.state.fitness, fashion: false, baby: false, electronic: false })} className={this.state.fitness ? "categories-group-list accordian-bg-products" : "categories-group-list"}>
                                                    <i className="mdi mdi-all-inclusive font-size-16 align-middle mr-2"></i> Combo Chargers
                                                    <i className={this.state.fitness === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i>
                                                </Link>
                                                <Collapse isOpen={this.state.fitness} id="collapseFour">
                                                    <div>
                                                        <ul className="list-unstyled categories-list mb-0">
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 82kW CCS2+ChaDEMO+Type2 AC, Triple-gun </Link></li>
                                                            <li><Link to="#"><i className="mdi mdi-circle-medium mr-1"></i> 142kW CCS2+ChaDEMO+Type2 AC, Triple-gun</Link></li>
                                                        </ul>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
        </CardBody>*/}

                                    <CardBody>
                                        <ChargerFilter filterChargers={this.filterChargers} manufacturers={[{name:"i-Pec"}]} 
                                        powerRatings={['3.3','7.2','14.4','22','30']} results={this.getFilteredResults}/>

                                    </CardBody>

                                    {/*<CardBody className="border-top">
                                        <div>
                                            <h5 className="font-size-14 mb-4">Price</h5>
                                            <br />
                                            <Nouislider range={{ min: 0, max: 600 }} tooltips={true} start={[100, 500]} connect />
                                        </div>
    </CardBody> */}

                                    {/*<div className="custom-accordion">
                                        <CardBody className="border-top">
                                            <div>
                                                <h5 className="font-size-14 mb-0"><Link to="#" className="text-dark d-block" onClick={() => this.setState({ discount: !this.state.discount })} >Discount <i className={this.state.discount === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i></Link></h5>

                                                <Collapse isOpen={this.state.discount} id="collapseExample1">

                                                    <div className="mt-4">
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio6" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio6">50% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio5" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio5">40% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio4" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio4">30% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio3" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio3">20% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio2" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio2">10% or more</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productdiscountRadio1" name="productdiscountRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productdiscountRadio1">Less than 10%</Label>
                                                        </div>
                                                    </div>

                                                </Collapse>
                                            </div>
                                        </CardBody>

                                        <CardBody className="border-top">
                                            <div>
                                                <h5 className="font-size-14 mb-0"><Link to="#" className="text-dark d-block" onClick={() => this.setState({ size: !this.state.size })}>Size <i className={this.state.size === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i></Link></h5>

                                                <Collapse isOpen={this.state.size} id="collapseExample2">

                                                    <div className="mt-4">
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productsizeRadio1" name="productsizeRadio" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productsizeRadio1">X-Large</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productsizeRadio2" name="productsizeRadio" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productsizeRadio2">Large</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productsizeRadio3" name="productsizeRadio" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productsizeRadio3">Medium</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productsizeRadio4" name="productsizeRadio" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productsizeRadio4">Small</Label>
                                                        </div>
                                                    </div>

                                                </Collapse>
                                            </div>
                                        </CardBody>
                                        <CardBody className="border-top">
                                            <div>
                                                <h5 className="font-size-14 mb-0"><Link to="#" className="collapsed text-dark d-block" onClick={() => this.setState({ rating: !this.state.rating })}>Customer Rating <i className={this.state.rating === true ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i></Link></h5>

                                                <Collapse isOpen={this.state.rating} id="collapseExample3">

                                                    <div className="mt-4">
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productratingRadio1" name="productratingRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productratingRadio1">4 <i className="mdi mdi-star text-warning"></i>  & Above</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productratingRadio2" name="productratingRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productratingRadio2">3 <i className="mdi mdi-star text-warning"></i>  & Above</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productratingRadio3" name="productratingRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productratingRadio3">2 <i className="mdi mdi-star text-warning"></i>  & Above</Label>
                                                        </div>
                                                        <div className="custom-control custom-radio mt-2">
                                                            <Input type="radio" id="productratingRadio4" name="productratingRadio1" className="custom-control-input" />
                                                            <Label className="custom-control-label" htmlFor="productratingRadio4">1 <i className="mdi mdi-star text-warning"></i></Label>
                                                        </div>
                                                    </div>

                                                </Collapse>
                                            </div>
                                        </CardBody>
</div> */}
                                </Card>
                            </Col>

                            <Col lg={9}>
                                <Card>
                                    <CardBody>
                                        <div>
                                        <h5 className="font-size-14"><i className="mdi mdi-location"></i> Firmware Upgrade</h5>
                                                                <div className="form-inline">

                                                                    <InputGroup className="mb-3">
                                                                        <Input type="text" className="form-control" placeholder="URL " onChange={this.firmwareInputChange} />
                                                                        <InputGroupAddon addonType="append">
                                                                            <Button color="light" type="button" onClick={() => this.firmware_upgrade_click()}>Upgrade</Button>
                                                                        </InputGroupAddon>
                                                                        
                                                                        <Button color="light" type="button" style={{marginLeft:".5rem"}}onClick={() => this.setState({data:chargers})}>Clear Filters</Button>
                                                                    </InputGroup>
                                                                   
                                                                </div>
                                        </div>
                                        <div className="table-responsive mt-3">
                                            <Table className="table-centered datatable dt-responsive nowrap " style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th style={{ width: "20px" }}>
                                                            <div className="custom-control custom-checkbox">
                                                                <Input type="checkbox" className="custom-control-input" id="customercheck" />
                                                                <Label className="custom-control-label" htmlFor="customercheck">&nbsp;</Label>
                                                            </div>
                                                        </th>
                                                        <th>Charger Id</th>
                                                        <th>Type</th>
                                                        <th>Power (kW)</th>
                                                        <th>Conns</th>
                                                        <th>Firmware</th>
                                                        <th>Status</th>
                                                        <th style={{ width: "120px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.data.map((customerData, key) =>
                                                            <tr key={key}>
                                                                <td>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <Input type="checkbox" className="custom-control-input" id={"customercheck" + key} />
                                                                        <Label className="custom-control-label" htmlFor={"customercheck" + key}>&nbsp;</Label>
                                                                    </div>
                                                                </td>

                                                                <td><Link to="cms-charger-detail">{customerData.chargerId}</Link></td>
                                                                <td>{customerData.type}</td>
                                                                <td>{customerData.power}</td>

                                                                <td>
                                                                    {customerData.connectors}
                                                                </td>
                                                                
                                                                <td>
                                                                    {customerData.firmware}
                                                                </td>
                                                                <td><span className={renderStatus(customerData.status)}>{customerData.status}</span></td>
                                                                <td>
                                                                    <Link to="#" className="mr-3 text-primary" id={"edit" + key}><i className="mdi mdi-pencil font-size-18"></i></Link>
                                                                    <UncontrolledTooltip target={"edit" + key} placement="top">
                                                                        Edit
                                                                    </UncontrolledTooltip>
                                                                    <Link to="#" className="text-danger" id={"delete" + key}><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                                    <UncontrolledTooltip target={"delete" + key} placement="top">
                                                                        Delete
                                                                    </UncontrolledTooltip>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default FirmwareMgmt;